

.ant-menu-dark .ant-menu-submenu-open.MenuEintrag {
  background-color: green !important;
border:0
}

.ant-menu-dark .ant-menu-submenu-selected.MenuEintrag {
  background-color: red !important;
border:0
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-menu .ant-menu-item{
  background-color: wheat;
  color: #f17e52;
  border-radius: 0px;
}

