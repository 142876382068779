table th {
    text-align: center !important;
}

.headline {
    float: left;
    margin-top: 30px;
}
.headerButtons {
    float: right;
    margin-top: 30px;
}

.contentBlock {
    clear: both;
}

td.oddEven {
    background-color: red !important;
    border: 1px solid red !important;
}

.oddEven {
    background-color: red !important;
    border: 1px solid red !important;
}
/*

.ant-table-cell {
    background-color: red !important;
    border: 1px solid red !important;
}

table td {
    background-color: blue !important;
    border: 1px solid blue !important;
}
 */