.headline {
  float: left;
  margin-top: 30px;
}

.headerButtons {
  float: right;
  margin-top: 30px;
  margin-right: 5px;
}

.datevHeader {
  font-size: 12px;

}

.settings_btn:hover .faIcon {
  color: #0090ff;
  cursor: pointer;

}

.infoBtn {
  color: #0090ff;
  cursor: pointer;
}
.contentBlock {
  clear: both;
}
.legende {
  padding-top: 6px;
  padding-right: 10px;
  text-align: right;
}

.dropdownAusgabe {
  width: 250px;
  font-size: 12px;
}
.dropdownKonten {
  font-size: 12px;
  width: 120px;
}
.datevTable {
  font-size: 10px;
  table-layout: fixed !important; /* rewrite inline styles */
}

.footerMenu {
  margin-top: 5vh;
}

.marginR5 {
  margin-right: 5px;
}
.standardBtn {
  width: 231px;
}
