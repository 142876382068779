/*
Globale CSS für alle Pages
*/

.content {
  /*background: #fff;*/
  padding: 24px;
  margin: 0;
  min-height: 28px;
  color: #f17e52;
}


.testCSS {
  background-color: yellow;
  color: #0090ff;
  text-align: center;
}