.logo {
  float: left;
  width: 220px;
  height: 31px;
  margin-right: 30px;
  /*margin: 16px 24px 16px 0;*/
  /*background: rgba(255, 255, 255, 0.3);*/
}

.versionnr {
  text-align: right;
  color: rgba(255, 255, 255, 0.65);
}

.popover {
  border-right: 7px;
}

.avatar {
  background-color: CornFlowerBlue;
  vertical-align: middle;
}

.profilPopupContainer {
  text-align: center;
  padding: 35px
}

.profilPopupAvatar {
  background-color: CornFlowerBlue;
  vertical-align: middle;
  margin: 0 auto;
  margin-bottom: 15px;
}

.logoutBtn {
  margin-top: 25px;
  margin-bottom: 25px;
}