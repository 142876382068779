.loginFormContainer {
  margin-left: 30%;
  margin-right: 30%;
  width: 75%;
  margin-top: 135px;
}

.loginContainer {
  background-color: white;
  height: 100vh;
  overflow: hidden;
}
.loginLogo {
  width: 300px;
  margin-left: 20px;
  margin-top: 20px;
}
