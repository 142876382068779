.btnBackground {
  text-align: center;
  vertical-align: center;
  color: white;
}
.btnMargin {
  margin-top: 100px;
  margin-bottom: 100px;
}

.icons {
  max-width: 300px;
  fill: red;
}
