.rootLayout {
  height: 100vh;
}

.contentLayout {
  padding: 0 24px 24px;
  background-color: white;
  height:100%;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: cornflowerblue;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #303030;

}
